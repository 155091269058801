<div class="min-h-full">
  <div class="bg-gray-800 pb-32">
    <div
      class="bg-gray-800 pb-32"
      style="
        background: url(./assets/video-preview.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position-y: 137px;
        padding-bottom: 680px;
      ">
      <!-- nav begin-->
      <header class="flex items-start items-center bg-white drop-shadow-sm pt-2 pb-2 px-8">
        <!-- Logo -->

        <a href="/" class="text-lg font-bold">
          <img class="h-32 p-2 bg-white" src="./assets/logo-france.jpg" />
        </a>
      </header>
    </div>

    <main class="-mt-82" style="margin-top: -33rem">
      <div class="mx-auto max-w-7xl mt-50 px-4 pb-12 sm:px-6 lg:px-8">
        <div class="rounded-lg bg-white px-5 py-6 shadow sm:px-6" style="margin-top: 0px">
          <div class="row">
            <div class="col-md-12 order-md-1 order-sm-2">
              <!-- MAIN CONTENT START -->

              <form [formGroup]="firstVisitorForm">
                <mat-horizontal-stepper #stepper labelPosition="bottom">
                  <mat-step label="Admin" *ngIf="_isAdmin == '1'">
                    <div class="step-container d-flex align-items-start flex-column">
                      <div class="container-fluid">
                        <div class="form">
                          <div class="grid gap-6 mb-6 md:grid-cols-2">
                            <div class="form-group">
                              <label class="form-label" for="company"> Company *</label>
                              <input
                                type="text"
                                class="form-control"
                                id="company"
                                formControlName="company"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Your Company" />
                            </div>

                            <!-- Firstname -->
                            <div class="form-group">
                              <label class="form-label" for="firstname"> Firstname *</label>
                              <input
                                type="text"
                                class="form-control"
                                id="firstname"
                                formControlName="firstname"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Your Firstname" />
                            </div>

                            <!-- Lastname -->
                            <div class="form-group">
                              <label class="form-label" for="lastname"> Lastname *</label>
                              <input
                                type="text"
                                class="form-control"
                                id="lastname"
                                formControlName="lastname"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Your Lastname" />
                            </div>

                            <!-- Position -->
                            <div class="form-group">
                              <label class="form-label" for="position"> Position *</label>
                              <input
                                type="text"
                                class="form-control"
                                id="position"
                                formControlName="position"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Your Position" />
                            </div>

                            <!-- E-Mail -->
                            <div class="form-group">
                              <label class="form-label" for="email"> E-Mail *</label>
                              <input
                                type="text"
                                class="form-control"
                                id="email"
                                formControlName="email"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="name@email.com" />
                            </div>

                            <!-- Country -->
                            <div class="form-group">
                              <label class="form-label" for="email"> Country *</label>

                              <select
                                class="form-control"
                                id="country"
                                formControlName="country"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="at">Austria</option>
                                <option value="ba">Bosnia-Herzegovina</option>
                                <option value="bg">Bulgaria</option>
                                <option value="hr">Croatia</option>
                                <option value="cz">Czech Republic</option>
                                <option value="fr">France</option>
                                <option value="de">Germany</option>
                                <option value="hu">Hungary</option>
                                <option value="xk">Kosovo</option>
                                <option value="mk">Macedonia</option>
                                <option value="mne">Montenegro</option>
                                <option value="pl">Poland</option>
                                <option value="ro">Romania</option>
                                <option value="xs">Serbia</option>
                                <option value="sk">Slovakia</option>
                                <option value="si">Slovenia</option>
                                <option value="ch">Switzerland</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="step-footer">
                      <button mat-raised-button matStepperNext color="primary">Next</button>
                    </div>
                  </mat-step>

                  <mat-step label="Company">
                    <div class="step-container d-flex align-items-start flex-column">
                      <div class="container-fluid">
                        <div class="form">
                          <div class="grid gap-6 mb-6 md:grid-cols-2">
                            <div>
                              <label for="adress" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Adress *</label>
                              <input
                                type="text"
                                id="adress"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder=""
                                formControlName="adress"
                                [ngClass]="{ 'is-invalid': submitted && firstVisitorForm.controls.adress.invalid }" />
                              <div *ngIf="submitted && firstVisitorForm.controls.adress.invalid" class="error">Adress is required</div>
                            </div>

                            <div>
                              <label for="areacode" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Areacode *</label>
                              <input
                                type="text"
                                id="areacode"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder=""
                                formControlName="areacode"
                                [ngClass]="{ 'is-invalid': submitted && firstVisitorForm.controls.areacode.invalid }" />
                              <div *ngIf="submitted && firstVisitorForm.controls.areacode.invalid" class="error">Areacode is required</div>
                            </div>
                            <div>
                              <label for="city" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">City *</label>
                              <input
                                type="text"
                                id="city"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder=""
                                formControlName="city"
                                [ngClass]="{ 'is-invalid': submitted && firstVisitorForm.controls.city.invalid }" />
                              <div *ngIf="submitted && firstVisitorForm.controls.city.invalid" class="error">City is required</div>
                            </div>
                            <div>
                              <label for="companyphone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >Company Phone *</label
                              >
                              <input
                                type="text"
                                id="companyphone"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder=""
                                formControlName="companyphone"
                                [ngClass]="{ 'is-invalid': submitted && firstVisitorForm.controls.companyphone.invalid }" />
                              <div *ngIf="submitted && firstVisitorForm.controls.companyphone.invalid" class="error">
                                Company Phone is required
                              </div>
                            </div>
                            <div>
                              <label for="companymail" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >Company E-Mail *</label
                              >
                              <input
                                type="text"
                                id="companymail"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder=""
                                formControlName="companymail"
                                [ngClass]="{ 'is-invalid': submitted && firstVisitorForm.controls.companymail.invalid }" />
                              <div *ngIf="submitted && firstVisitorForm.controls.companymail.invalid" class="error">
                                Company E-Mail is required
                              </div>
                            </div>
                            <div>
                              <label for="website" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Website *</label>
                              <input
                                type="text"
                                id="website"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder=""
                                formControlName="website"
                                [ngClass]="{ 'is-invalid': submitted && firstVisitorForm.controls.website.invalid }" />
                              <div *ngIf="submitted && firstVisitorForm.controls.website.invalid" class="error">Website is required</div>
                            </div>

                            <div>
                              <label for="icphone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >Phone Person *</label
                              >
                              <input
                                type="text"
                                id="icphone"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder=""
                                formControlName="icphone"
                                [ngClass]="{ 'is-invalid': submitted && firstVisitorForm.controls.icphone.invalid }" />
                              <div *ngIf="submitted && firstVisitorForm.controls.icphone.invalid" class="error">
                                Phone Person is required
                              </div>
                            </div>

                            <div>
                              <label for="taxid_uid" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >Tax-ID (VAT) *</label
                              >
                              <input
                                type="text"
                                id="taxid_uid"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder=""
                                formControlName="taxid_uid"
                                [ngClass]="{ 'is-invalid': submitted && firstVisitorForm.controls.taxid_uid.invalid }" />
                              <div *ngIf="submitted && firstVisitorForm.controls.taxid_uid.invalid" class="error">
                                Tax-ID (VAT) is required
                              </div>
                            </div>

                            <div>
                              <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >Your Profile Picture</label
                              >
                              <file-pond
                                id="myPondProfile"
                                name="myPondProfile"
                                [options]="pondOptions"
                                [files]="pondFiles"
                                (oninit)="pondHandleInit()"
                                (onaddfile)="pondHandleAddFile($event)"
                                (onactivatefile)="pondHandleActivateFile($event)">
                              </file-pond>
                            </div>

                            <img
                              *ngIf="_profile_image"
                              class="aspect-[4/5] w-52 flex-none rounded-2xl object-cover"
                              src="{{ imageStorage }}{{ _profile_image }}"
                              alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="step-footer">
                      <button mat-raised-button matStepperNext color="primary">Next</button>
                    </div>
                  </mat-step>

                  <mat-step label="Identity">
                    <div class="step-container d-flex align-items-start flex-column">
                      <div class="container-fluid">
                        <div class="form">
                          <div class="row">
                            <div>
                              <p class="font-semibold">Please fill in the Identity information.</p>
                            </div>

                            <div class="grid gap-6 mb-6 mt-6 md:grid-cols-2" formGroupName="compIdentityGroup">
                              <div class="flex items-center">
                                <input id="generalisttour" formControlName="generalisttour" type="checkbox" class="form-check-input" />
                                <label for="generalisttour" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Generalist tour operator</label
                                >
                              </div>

                              <div class="flex items-center">
                                <input id="specialisttour" formControlName="specialisttour" type="checkbox" class="form-check-input" />
                                <label for="specialisttour" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Specialist tour operator</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="tourownretailing" formControlName="tourownretailing" type="checkbox" class="form-check-input" />
                                <label for="tourownretailing" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Tour operator - Own retailing</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input
                                  id="tourretailingtravelagencies"
                                  formControlName="tourretailingtravelagencies"
                                  type="checkbox"
                                  class="form-check-input" />
                                <label for="tourretailingtravelagencies" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Tour operator - Retailing through travel agencies
                                </label>
                              </div>

                              <div class="flex items-center">
                                <input
                                  id="touroperatormember"
                                  formControlName="touroperatormember"
                                  type="checkbox"
                                  class="form-check-input" />
                                <label for="touroperatormember" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Tour operator member of a holding company
                                </label>
                              </div>
                              <div class="flex items-center">
                                <input id="travel" formControlName="travel" type="checkbox" class="form-check-input" />
                                <label for="travel" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Travel agency</label>
                              </div>
                              <div class="flex items-center">
                                <input id="incentive" formControlName="incentive" type="checkbox" class="form-check-input" />
                                <label for="incentive" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Incentive agency</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input
                                  id="inernationalincoming"
                                  formControlName="inernationalincoming"
                                  type="checkbox"
                                  class="form-check-input" />
                                <label for="inernationalincoming" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >International incoming agency</label
                                >
                              </div>

                              <div class="flex items-center">
                                <input id="coach" formControlName="coach" type="checkbox" class="form-check-input" />
                                <label for="coach" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Coach company</label>
                              </div>
                              <!-- dyn fields now
                              <div class="flex items-center">
                                <input id="carrental" formControlName="carrental" type="checkbox" class="form-check-input" />
                                <label for="carrental" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Car rental company
                                </label>
                              </div>
                              -->
                              <div class="flex items-center">
                                <input
                                  id="hotelbookingcallcenter"
                                  formControlName="hotelbookingcallcenter"
                                  type="checkbox"
                                  class="form-check-input" />
                                <label for="hotelbookingcallcenter" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Hotel booking call center</label
                                >
                              </div>
                              <!-- dyn fields now
                              <div class="flex items-center">
                                <input id="aircarrier" formControlName="aircarrier" type="checkbox" class="form-check-input" />
                                <label for="aircarrier" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Air carrier</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="seacarrier" formControlName="seacarrier" type="checkbox" class="form-check-input" />
                                <label for="seacarrier" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Sea carrier</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="roadcarrier" formControlName="roadcarrier" type="checkbox" class="form-check-input" />
                                <label for="roadcarrier" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Road carrier</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="railcarrier" formControlName="railcarrier" type="checkbox" class="form-check-input" />
                                <label for="railcarrier" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Rail carrier</label
                                >
                              </div>
                              -->
                              <!-- dynamic fields identity -->
                              <ng-container *ngFor="let key of getKeysIdentity(); let i = index">
                                <div *ngIf="show_dyn_identity[i]" class="flex items-center">
                                  <input [id]="key" [formControlName]="key" type="checkbox" class="form-check-input" />
                                  <label [for]="key" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                    {{ caption_dyn_identity[key] }}
                                  </label>
                                </div>
                              </ng-container>
                              <!-- end dyn fields -->

                              <div *ngIf="submitted && firstVisitorForm.controls['compIdentityGroup'].invalid" class="error mb-3">
                                <p>At least one box is required to check.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="step-footer">
                      <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                      <button mat-raised-button matStepperNext color="primary">Next</button>
                    </div>
                  </mat-step>

                  <mat-step label="Numbers">
                    <div class="step-container d-flex align-items-start flex-column">
                      <div class="container-fluid">
                        <div class="form">
                          <div class="row">
                            <div>
                              <p class="font-semibold">Please fill in the Numbers information.</p>
                            </div>

                            <div class="grid gap-6 mb-6 mt-6 md:grid-cols-2">
                              <div class="form-group">
                                <label class="form-label" for="numberemployees"> Number of employees in the company</label>
                                <div class="flex items-center">
                                  <input
                                    id="numberemployees"
                                    formControlName="numberemployees"
                                    type="radio"
                                    value="1"
                                    checked
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                  <label for="numberemployees" class="ml-3 block text-sm font-medium text-gray-700"> &lt; 5</label>
                                </div>

                                <div class="flex items-center">
                                  <input
                                    id="numberemployees"
                                    formControlName="numberemployees"
                                    type="radio"
                                    value="2"
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                  <label for="numberemployees" class="ml-3 block text-sm font-medium text-gray-700">5 &lt;50</label>
                                </div>
                                <div class="flex items-center">
                                  <input
                                    id="numberemployees"
                                    formControlName="numberemployees"
                                    type="radio"
                                    value="3"
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                  <label for="numberemployees" class="ml-3 block text-sm font-medium text-gray-700"> &gt; 50</label>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="form-label" for="volumegroups"> Volume of travellers " Groups " to France per year</label>
                                <div class="flex items-center">
                                  <input
                                    id="volumegroups"
                                    formControlName="volumegroups"
                                    type="radio"
                                    value="1"
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                  <label for="volumegroups" class="ml-3 block text-sm font-medium text-gray-700"> &lt; 500</label>
                                </div>

                                <div class="flex items-center">
                                  <input
                                    id="volumegroups"
                                    formControlName="volumegroups"
                                    type="radio"
                                    value="2"
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                  <label for="volumegroups" class="ml-3 block text-sm font-medium text-gray-700">500 &lt; 1000</label>
                                </div>
                                <div class="flex items-center">
                                  <input
                                    id="volumegroups"
                                    formControlName="volumegroups"
                                    type="radio"
                                    value="3"
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                  <label for="volumegroups" class="ml-3 block text-sm font-medium text-gray-700">1000 &lt; 5000</label>
                                </div>
                                <div class="flex items-center">
                                  <input
                                    id="volumegroups"
                                    formControlName="volumegroups"
                                    type="radio"
                                    value="4"
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                  <label for="volumegroups" class="ml-3 block text-sm font-medium text-gray-700"> &gt; 5000</label>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="form-label" for="volumeindividuals">
                                  Volume of " Individuals " travellers to France per year</label
                                >
                                <div class="flex items-center">
                                  <input
                                    id="volumeindividuals"
                                    formControlName="volumeindividuals"
                                    type="radio"
                                    value="1"
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                  <label for="volumeindividuals" class="ml-3 block text-sm font-medium text-gray-700"> &lt; 100</label>
                                </div>

                                <div class="flex items-center">
                                  <input
                                    id="volumeindividuals"
                                    formControlName="volumeindividuals"
                                    type="radio"
                                    value="2"
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                  <label for="volumeindividuals" class="ml-3 block text-sm font-medium text-gray-700">100 &lt; 500</label>
                                </div>
                                <div class="flex items-center">
                                  <input
                                    id="volumeindividuals"
                                    formControlName="volumeindividuals"
                                    type="radio"
                                    value="3"
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                  <label for="volumeindividuals" class="ml-3 block text-sm font-medium text-gray-700">500 &lt; 1000</label>
                                </div>
                                <div class="flex items-center">
                                  <input
                                    id="volumeindividuals"
                                    formControlName="volumeindividuals"
                                    type="radio"
                                    value="4"
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                  <label for="volumeindividuals" class="ml-3 block text-sm font-medium text-gray-700"> &gt; 1000</label>
                                </div>
                              </div>

                              <div class="form-group">
                                <label class="form-label" for="numberbrochures"> Number of brochures per year with France featured</label>
                                <div class="flex items-center">
                                  <input
                                    id="numberbrochures"
                                    formControlName="numberbrochures"
                                    type="radio"
                                    value="0"
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                  <label for="numberbrochures" class="ml-3 block text-sm font-medium text-gray-700">0</label>
                                </div>

                                <div class="flex items-center">
                                  <input
                                    id="numberbrochures"
                                    formControlName="numberbrochures"
                                    type="radio"
                                    value="1"
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                  <label for="numberbrochures" class="ml-3 block text-sm font-medium text-gray-700">1</label>
                                </div>
                                <div class="flex items-center">
                                  <input
                                    id="numberbrochures"
                                    formControlName="numberbrochures"
                                    type="radio"
                                    value="2"
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                  <label for="numberbrochures" class="ml-3 block text-sm font-medium text-gray-700">2</label>
                                </div>
                                <div class="flex items-center">
                                  <input
                                    id="numberbrochures"
                                    formControlName="numberbrochures"
                                    type="radio"
                                    value="3"
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                  <label for="numberbrochures" class="ml-3 block text-sm font-medium text-gray-700">3</label>
                                </div>
                                <div class="flex items-center">
                                  <input
                                    id="numberbrochures"
                                    formControlName="numberbrochures"
                                    type="radio"
                                    value="4"
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                  <label for="numberbrochures" class="ml-3 block text-sm font-medium text-gray-700"
                                    >4 ou/et plus / 4 or/and more
                                  </label>
                                </div>
                              </div>

                              <div class="flex items-center">
                                <input id="palsace" formControlName="palsace" type="checkbox" class="form-check-input" />
                                <label for="palsace" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Brochure is only online</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="onlinebooking" formControlName="onlinebooking" type="checkbox" class="form-check-input" />
                                <label for="onlinebooking" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Can we book on line on your website some products featuring France ?</label
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="step-footer">
                      <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                      <button mat-raised-button matStepperNext color="primary">Next</button>
                    </div>
                  </mat-step>

                  <mat-step label="Offer">
                    <div class="step-container d-flex align-items-start flex-column">
                      <div class="container-fluid">
                        <div class="form">
                          <div class="row">
                            <div>
                              <p class="font-semibold">Please fill in the Programming information.</p>
                            </div>

                            <div class="grid gap-6 mb-6 mt-6 md:grid-cols-2" formGroupName="programmingGroup">
                              <div class="flex items-center">
                                <input id="pauvergne" formControlName="pauvergne" type="checkbox" class="form-check-input" />
                                <label for="pauvergne" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Auvergne - Rhône-Alps</label
                                >
                              </div>

                              <div class="flex items-center">
                                <input id="pbrittany" formControlName="pbrittany" type="checkbox" class="form-check-input" />
                                <label for="pbrittany" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Brittany</label>
                              </div>
                              <div class="flex items-center">
                                <input id="pburgundy" formControlName="pburgundy" type="checkbox" class="form-check-input" />
                                <label for="pburgundy" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Burgundy-Franche-Comté</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="pcorsica" formControlName="pcorsica" type="checkbox" class="form-check-input" />
                                <label for="pcorsica" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Corsica</label>
                              </div>

                              <div class="flex items-center">
                                <input id="pchampagne" formControlName="pchampagne" type="checkbox" class="form-check-input" />
                                <label for="pchampagne" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Eastern France - Alsace, Champagne, Lorraine</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="ploirevalley" formControlName="ploirevalley" type="checkbox" class="form-check-input" />
                                <label for="ploirevalley" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Loire Valley</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="pnordpasdecalais" formControlName="pnordpasdecalais" type="checkbox" class="form-check-input" />
                                <label for="pnordpasdecalais" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Northern France - Picardy, Nord Pas de Calais</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="pnormandy" formControlName="pnormandy" type="checkbox" class="form-check-input" />
                                <label for="pnormandy" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Normandy</label>
                              </div>

                              <div class="flex items-center">
                                <input id="plimousin" formControlName="plimousin" type="checkbox" class="form-check-input" />
                                <label for="plimousin" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Nouvelle-Aquitaine - Aquitaine, Poitou-Charentes, Limousin</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="pfranche" formControlName="pfranche" type="checkbox" class="form-check-input" />
                                <label for="pfranche" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">French Alps</label>
                              </div>
                              <div class="flex items-center">
                                <input id="plancuedoc" formControlName="plancuedoc" type="checkbox" class="form-check-input" />
                                <label for="plancuedoc" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Occitania - Midi-Pyrenees, Languedoc-Roussillon</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="poverseas" formControlName="poverseas" type="checkbox" class="form-check-input" />
                                <label for="poverseas" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Overseas</label>
                              </div>

                              <div class="flex items-center">
                                <input id="pparis" formControlName="pparis" type="checkbox" class="form-check-input" />
                                <label for="pparis" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Paris Region</label>
                              </div>
                              <div class="flex items-center">
                                <input id="pwesternloire" formControlName="pwesternloire" type="checkbox" class="form-check-input" />
                                <label for="pwesternloire" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Pays de la Loire</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="pprovence" formControlName="pprovence" type="checkbox" class="form-check-input" />
                                <label for="pprovence" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Provence-Alpes Côte d'Azur</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="pall" formControlName="pall" type="checkbox" class="form-check-input" />
                                <label for="pall" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">All France</label>
                              </div>

                              <!-- dynamic fields programming -->
                              <ng-container *ngFor="let key of getKeysProgramming(); let i = index">
                                <div *ngIf="show_dyn_programming[i]" class="flex items-center">
                                  <input [id]="key" [formControlName]="key" type="checkbox" class="form-check-input" />
                                  <label [for]="key" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                    {{ caption_dyn_programming[key] }}
                                  </label>
                                </div>
                              </ng-container>
                              <!-- end dyn fields -->
                            </div>
                            <div *ngIf="submitted && firstVisitorForm.controls['programmingGroup'].invalid" class="error mb-3">
                              <p>At least one box is required to check.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="step-footer">
                      <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                      <button mat-raised-button matStepperNext color="primary">Next</button>
                    </div>
                  </mat-step>

                  <mat-step label="Products">
                    <div class="step-container d-flex align-items-start flex-column">
                      <div class="container-fluid">
                        <div class="form">
                          <div class="row">
                            <div>
                              <p class="font-semibold">Please fill in the Products offered information.</p>
                            </div>

                            <div class="grid gap-6 mb-6 mt-6 md:grid-cols-2" formGroupName="productsGroup">
                              <!-- dyn
                              <div class="flex items-center">
                                <input id="pobusiness" formControlName="pobusiness" type="checkbox" class="form-check-input" />
                                <label for="pobusiness" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Business</label>
                              </div>
                              -->
                              <div class="flex items-center">
                                <input id="pobeach" formControlName="pobeach" type="checkbox" class="form-check-input" />
                                <label for="pobeach" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Beach resorts</label>
                              </div>
                              <div class="flex items-center">
                                <input id="pocruises" formControlName="pocruises" type="checkbox" class="form-check-input" />
                                <label for="pocruises" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Cruises</label>
                              </div>
                              <div class="flex items-center">
                                <input id="pobarging" formControlName="pobarging" type="checkbox" class="form-check-input" />
                                <label for="pobarging" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Barging</label>
                              </div>
                              <div class="flex items-center">
                                <input id="pogolf" formControlName="pogolf" type="checkbox" class="form-check-input" />
                                <label for="pogolf" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Golf</label>
                              </div>
                              <!-- dyn
                              <div class="flex items-center">
                                <input id="poyouth" formControlName="poyouth" type="checkbox" class="form-check-input" />
                                <label for="poyouth" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Youth tourism</label>
                              </div>


                              <div class="flex items-center">
                                <input id="posummer" formControlName="posummer" type="checkbox" class="form-check-input" />
                                <label for="posummer" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Summer resorts</label
                                >
                              </div>
                              -->
                              <div class="flex items-center">
                                <input id="ponature" formControlName="ponature" type="checkbox" class="form-check-input" />
                                <label for="ponature" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Nature</label>
                              </div>
                              <!-- dyn
                              <div class="flex items-center">
                                <input id="poreligious" formControlName="poreligious" type="checkbox" class="form-check-input" />
                                <label for="poreligious" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Religious tourism</label
                                >
                              </div>
                              -->
                              <div class="flex items-center">
                                <input id="powinter" formControlName="powinter" type="checkbox" class="form-check-input" />
                                <label for="powinter" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Ski</label>
                              </div>

                              <div class="flex items-center">
                                <input id="pocultural" formControlName="pocultural" type="checkbox" class="form-check-input" />
                                <label for="pocultural" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Cultural tours</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="pogastronomy" formControlName="pogastronomy" type="checkbox" class="form-check-input" />
                                <label for="pogastronomy" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Champagne, Gastronomy and wine tours</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="pourban" formControlName="pourban" type="checkbox" class="form-check-input" />
                                <label for="pourban" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Urban tourism and city break</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="poactive" formControlName="poactive" type="checkbox" class="form-check-input" />
                                <label for="poactive" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Active holidays and hiking</label
                                >
                              </div>

                              <div class="flex items-center">
                                <input id="pochampagne" formControlName="pochampagne" type="checkbox" class="form-check-input" />
                                <label for="pochampagne" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Luxury products</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="pofitness" formControlName="pofitness" type="checkbox" class="form-check-input" />
                                <label for="pofitness" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >tailored-made products</label
                                >
                              </div>

                              <!-- dynamic fields products -->
                              <ng-container *ngFor="let key of getKeysProducts(); let i = index">
                                <div *ngIf="show_dyn_products[i]" class="flex items-center">
                                  <input [id]="key" [formControlName]="key" type="checkbox" class="form-check-input" />
                                  <label [for]="key" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                    {{ caption_dyn_products[key] }}
                                  </label>
                                </div>
                              </ng-container>
                              <!-- end dyn fields -->
                            </div>
                            <div *ngIf="submitted && firstVisitorForm.controls['productsGroup'].invalid" class="error mb-3">
                              <p>At least one box is required to check.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="step-footer">
                      <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                      <button mat-raised-button matStepperNext color="primary">Next</button>
                    </div>
                  </mat-step>

                  <mat-step label="Request">
                    <div class="step-container d-flex align-items-start flex-column">
                      <div class="container-fluid">
                        <div class="form">
                          <div class="row">
                            <div>
                              <p class="font-semibold">Please fill in the Regions of Interest.</p>
                            </div>

                            <div class="grid gap-6 mb-6 mt-6 md:grid-cols-2" formGroupName="soughtRegionsGroup">
                              <div class="flex items-center">
                                <input id="sauvergne" formControlName="sauvergne" type="checkbox" class="form-check-input" />
                                <label for="sauvergne" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Auvergne - Rhône-Alps</label
                                >
                              </div>

                              <div class="flex items-center">
                                <input id="sbrittany" formControlName="sbrittany" type="checkbox" class="form-check-input" />
                                <label for="sbrittany" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Brittany</label>
                              </div>
                              <div class="flex items-center">
                                <input id="sburgundy" formControlName="sburgundy" type="checkbox" class="form-check-input" />
                                <label for="sburgundy" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Burgundy-Franche-Comté</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="scorsica" formControlName="scorsica" type="checkbox" class="form-check-input" />
                                <label for="scorsica" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Corsica</label>
                              </div>

                              <div class="flex items-center">
                                <input id="schampagne" formControlName="schampagne" type="checkbox" class="form-check-input" />
                                <label for="schampagne" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Eastern France - Alsace, Champagne, Lorraine</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="sloire" formControlName="sloire" type="checkbox" class="form-check-input" />
                                <label for="sloire" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Loire Valley</label>
                              </div>
                              <div class="flex items-center">
                                <input id="snordpasdecalais" formControlName="snordpasdecalais" type="checkbox" class="form-check-input" />
                                <label for="snordpasdecalais" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Northern France - Picardy, Nord Pas de Calais</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="snormandy" formControlName="snormandy" type="checkbox" class="form-check-input" />
                                <label for="snormandy" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Normandy</label>
                              </div>

                              <div class="flex items-center">
                                <input id="slimousin" formControlName="slimousin" type="checkbox" class="form-check-input" />
                                <label for="slimousin" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Nouvelle-Aquitaine - Aquitaine, Poitou-Charentes, Limousin</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="sfranche" formControlName="sfranche" type="checkbox" class="form-check-input" />
                                <label for="sfranche" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">French Alps</label>
                              </div>
                              <div class="flex items-center">
                                <input id="slanguedoc" formControlName="slanguedoc" type="checkbox" class="form-check-input" />
                                <label for="slanguedoc" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Occitania - Midi-Pyrenees, Languedoc-Roussillon</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="soverseas" formControlName="soverseas" type="checkbox" class="form-check-input" />
                                <label for="soverseas" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Overseas</label>
                              </div>

                              <div class="flex items-center">
                                <input id="sparis" formControlName="sparis" type="checkbox" class="form-check-input" />
                                <label for="sparis" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Paris Region</label>
                              </div>
                              <div class="flex items-center">
                                <input id="swesternloire" formControlName="swesternloire" type="checkbox" class="form-check-input" />
                                <label for="swesternloire" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Pays de la Loire</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="sprovence" formControlName="sprovence" type="checkbox" class="form-check-input" />
                                <label for="sprovence" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Provence-Alpes Côte d'Azur</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="sall" formControlName="sall" type="checkbox" class="form-check-input" />
                                <label for="sall" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">All France</label>
                              </div>

                              <!-- dynamic fields regions  -->
                              <ng-container *ngFor="let key of getKeysRegions(); let i = index">
                                <div *ngIf="show_dyn_regions[i]" class="flex items-center">
                                  <input [id]="key" [formControlName]="key" type="checkbox" class="form-check-input" />
                                  <label [for]="key" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                    {{ caption_dyn_regions[key] }}
                                  </label>
                                </div>
                              </ng-container>
                              <!-- end dyn fields -->
                            </div>
                            <div *ngIf="submitted && firstVisitorForm.controls['soughtRegionsGroup'].invalid" class="error mb-3">
                              <p>At least one box is required to check.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="step-footer">
                      <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                      <button mat-raised-button matStepperNext color="primary">Next</button>
                    </div>
                  </mat-step>

                  <mat-step label="Products">
                    <div class="step-container d-flex align-items-start flex-column">
                      <div class="container-fluid">
                        <div class="form">
                          <div class="row">
                            <div>
                              <p class="font-semibold">Please fill in the Kind of Products requested information.</p>
                            </div>

                            <div class="grid gap-6 mb-6 mt-6 md:grid-cols-2" formGroupName="productsRequested">
                              <div class="flex items-center">
                                <input id="khotelgroup" formControlName="khotelgroup" type="checkbox" class="form-check-input" />
                                <label for="khotelgroup" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Hotel group</label
                                >
                              </div>
                              <!-- dyn
                              <div class="flex items-center">
                                <input id="k2starhotel" formControlName="k2starhotel" type="checkbox" class="form-check-input" />
                                <label for="k2starhotel" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >2-star Hotel</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="k3starhotel" formControlName="k3starhotel" type="checkbox" class="form-check-input" />
                                <label for="k3starhotel" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >3-star hotel</label
                                >
                              </div>

                              <div class="flex items-center">
                                <input id="k4starhotel" formControlName="k4starhotel" type="checkbox" class="form-check-input" />
                                <label for="k4starhotel" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >4-star and 5* hotel</label
                                >
                              </div>
                              -->
                              <div class="flex items-center">
                                <input id="palacehotels" formControlName="palacehotels" type="checkbox" class="form-check-input" />
                                <label for="palacehotels" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Palace hotels</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="kvacation" formControlName="kvacation" type="checkbox" class="form-check-input" />
                                <label for="kvacation" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Vacation residence</label
                                >
                              </div>

                              <div class="flex items-center">
                                <input id="kholidayclub" formControlName="kholidayclub" type="checkbox" class="form-check-input" />
                                <label for="kholidayclub" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Holiday club</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="kbandb" formControlName="kbandb" type="checkbox" class="form-check-input" />
                                <label for="kbandb" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >B&B and private houses rental</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="krestaurant" formControlName="krestaurant" type="checkbox" class="form-check-input" />
                                <label for="krestaurant" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Restaurant</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="kincomingagency" formControlName="kincomingagency" type="checkbox" class="form-check-input" />
                                <label for="kincomingagency" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Incoming agency</label
                                >
                              </div>

                              <div class="flex items-center">
                                <input id="kcongress" formControlName="kcongress" type="checkbox" class="form-check-input" />
                                <label for="kcongress" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Congress and incentive organisation</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="gsightseeing" formControlName="gsightseeing" type="checkbox" class="form-check-input" />
                                <label for="gsightseeing" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Sightseeing & guide</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="kairtrain" formControlName="kairtrain" type="checkbox" class="form-check-input" />
                                <label for="kairtrain" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Air and train carrier</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="kcoach" formControlName="kcoach" type="checkbox" class="form-check-input" />
                                <label for="kcoach" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Coach carrier</label>
                              </div>

                              <div class="flex items-center">
                                <input id="kbarging" formControlName="kbarging" type="checkbox" class="form-check-input" />
                                <label for="kbarging" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Barging</label>
                              </div>
                              <!-- dyn
                              <div class="flex items-center">
                                <input id="kleisure" formControlName="kleisure" type="checkbox" class="form-check-input" />
                                <label for="kleisure" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Leisure or entertainment park</label
                                >
                              </div>
                              -->
                              <div class="flex items-center">
                                <input id="kmuseum" formControlName="kmuseum" type="checkbox" class="form-check-input" />
                                <label for="kmuseum" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Museum, monument, castle, site</label
                                >
                              </div>
                              <!-- dyn
                              <div class="flex items-center">
                                <input id="kshow" formControlName="kshow" type="checkbox" class="form-check-input" />
                                <label for="kshow" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Show, cabaret, theater</label
                                >
                              </div>
                              -->
                              <div class="flex items-center">
                                <input id="kmall" formControlName="kmall" type="checkbox" class="form-check-input" />
                                <label for="kmall" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Shopping Mall, department store</label
                                >
                              </div>
                              <div class="flex items-center">
                                <input id="ktouristoffice" formControlName="ktouristoffice" type="checkbox" class="form-check-input" />
                                <label for="ktouristoffice" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                  >Tourist office</label
                                >
                              </div>

                              <!-- dynamic fields products requested  -->
                              <ng-container *ngFor="let key of getKeysProductsReq(); let i = index">
                                <div *ngIf="show_dyn_productsreq[i]" class="flex items-center">
                                  <input [id]="key" [formControlName]="key" type="checkbox" class="form-check-input" />
                                  <label [for]="key" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                    {{ caption_dyn_productsreq[key] }}
                                  </label>
                                </div>
                              </ng-container>
                              <!-- end dyn fields -->

                              <div class="flex items-center">
                                <label for="danydietryallergies" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                  >Others &nbsp;</label
                                >
                                <input
                                  type="text"
                                  id="danydietryallergies"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder=""
                                  formControlName="danydietryallergies" />
                              </div>
                            </div>
                            <div *ngIf="submitted && firstVisitorForm.controls['productsRequested'].invalid" class="error mb-3">
                              <p>At least one box is required to check.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="step-footer">
                      <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                      <button mat-raised-button matStepperNext color="primary">Next</button>
                    </div>
                  </mat-step>

                  <ng-container *ngIf="noCountrySelection; else countrySelection">
                    <ng-container *ngIf="_country === _veranstaltungsland; else otherCountry">
                      <mat-step [label]="getCountryName(_country)">
                        <div class="step-container d-flex align-items-start flex-column">
                          <div class="container-fluid">
                            <fieldset class="mt-6 mb-6" *ngIf="showDays">
                              <legend class="contents text-sm font-semibold leading-6 text-gray-900">
                                Which Days will you attend? (if you wish to attend on both days, please check both boxes)
                              </legend>
                              <div class="mx-4 mt-2">
                                <div class="flex items-top">
                                  <input
                                    type="checkbox"
                                    formControlName="chkcity1"
                                    id="chkcity1"
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                  <label for="chkcity1" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                    {{ _day1 }}</label
                                  >
                                </div>

                                <div class="flex items-top">
                                  <input
                                    type="checkbox"
                                    formControlName="chkcity2"
                                    id="chkcity2"
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                  <label for="chkcity2" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                    {{ _day2 }}</label
                                  >
                                </div>
                              </div>
                            </fieldset>

                            <div class="form">
                              <div class="row">
                                <fieldset class="">
                                  <legend class="contents text-sm font-semibold leading-6 text-gray-900 mt-4">
                                    Please let us know what you plan to do (all these services are included in your participation package):
                                  </legend>
                                  <br /><br />
                                  <legend class="contents text-sm leading-6 font-medium text-gray-900">Yes, {{ __hotel_parking }}</legend>
                                  <div class="mx-4 mt-2">
                                    <div class="flex items-top">
                                      <input
                                        type="checkbox"
                                        formControlName="garage_day1"
                                        id="garage_day1"
                                        class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                      <label
                                        for="garage_day1"
                                        class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                        {{ _day1 }}</label
                                      >
                                    </div>

                                    <div class="flex items-top">
                                      <input
                                        type="checkbox"
                                        formControlName="garage_day2"
                                        id="garage_day2"
                                        class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                      <label
                                        for="garage_day2"
                                        class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                        {{ _day2 }}</label
                                      >
                                    </div>
                                  </div>
                                </fieldset>

                                <fieldset class="">
                                  <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                    Yes, I would like to attend the lunch at the {{ _hotel_info }} on
                                  </legend>
                                  <div class="mx-4 mt-2">
                                    <div class="flex items-top">
                                      <input
                                        type="checkbox"
                                        formControlName="lunch_day1"
                                        id="lunch_day1"
                                        class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                      <label
                                        for="lunch_day1"
                                        class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                        {{ _day1 }}</label
                                      >
                                    </div>

                                    <div class="flex items-top">
                                      <input
                                        type="checkbox"
                                        formControlName="lunch_day2"
                                        id="lunch_day2"
                                        class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                      <label
                                        for="lunch_day2"
                                        class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                        {{ _day2 }}</label
                                      >
                                    </div>
                                  </div>
                                </fieldset>

                                <div class="grid gap-6 mb-6 mt-6">
                                  <div class="flex items-center">
                                    <input
                                      id="attendance_event"
                                      formControlName="attendance_event"
                                      type="checkbox"
                                      class="form-check-input" />
                                    <label for="attendance_event" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >Yes, I would like to attend the „France“ evening at the {{ _event_space }} on
                                      {{ _event_date }}</label
                                    >
                                  </div>
                                  <div class="flex items-center">
                                    <input
                                      id="hotel_stay_night"
                                      formControlName="hotel_stay_night"
                                      type="checkbox"
                                      class="form-check-input" />
                                    <label for="hotel_stay_night" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >For visitors, who are not living in {{ __txt_Event_City }}: <br />
                                      Yes, I would like Atout France to book a room in my name for the night of {{ _book_night }} <br />at
                                      the {{ __txt_Hotel_Night }}
                                    </label>
                                  </div>
                                </div>

                                <div class="row mb-6 mt-6">
                                  <label for="special_wishes" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >Special Wishes (diet, twin beds, ...)</label
                                  >
                                  <textarea
                                    id="special_wishes"
                                    formControlName="special_wishes"
                                    rows="4"
                                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder=""></textarea>
                                </div>
                                <p>&nbsp;</p>
                              </div>
                              <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                              <button mat-raised-button matStepperNext color="primary">Next</button>
                            </div>
                          </div>
                        </div>
                      </mat-step>
                    </ng-container>

                    <ng-template #otherCountry>
                      <mat-step [label]="getCountryName(_country)">
                        <div class="step-container d-flex align-items-start flex-column">
                          <div class="container-fluid">
                            <fieldset class="mt-6 mb-6" *ngIf="showDays">
                              <legend class="contents text-sm font-semibold leading-6 text-gray-900">
                                Which Days will you attend? (if you wish to attend on both days, please check both boxes)
                              </legend>
                              <div class="mx-4 mt-4">
                                <div class="flex items-top">
                                  <input
                                    type="checkbox"
                                    formControlName="chkcity1"
                                    id="chkcity1"
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                  <label for="chkcity1" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                    {{ _day1 }}</label
                                  >
                                </div>

                                <div class="flex items-top">
                                  <input
                                    type="checkbox"
                                    formControlName="chkcity2"
                                    id="chkcity2"
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                  <label for="chkcity2" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                    {{ _day2 }}</label
                                  >
                                </div>
                              </div>
                            </fieldset>

                            <div class="form">
                              <div class="row">
                                <fieldset class="">
                                  <legend class="contents text-sm font-semibold leading-6 text-gray-900 mt-4">
                                    Please let us know what you plan to do (all these services are included in your participation package):
                                  </legend>
                                  <br /><br />

                                  <legend class="contents text-sm leading-6 font-medium text-gray-900">Yes, {{ __hotel_parking }}</legend>
                                  <div class="mx-4 mt-2">
                                    <div class="flex items-top">
                                      <input
                                        type="checkbox"
                                        formControlName="garage_day1"
                                        id="garage_day1"
                                        class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                      <label
                                        for="garage_day1"
                                        class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                        {{ _day1 }}</label
                                      >
                                    </div>

                                    <div class="flex items-top">
                                      <input
                                        type="checkbox"
                                        formControlName="garage_day2"
                                        id="garage_day2"
                                        class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                      <label
                                        for="garage_day2"
                                        class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                        {{ _day2 }}</label
                                      >
                                    </div>
                                  </div>
                                </fieldset>

                                <fieldset class="">
                                  <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                    Yes, I would like to attend the lunch at the hotel {{ _hotel_info }} on
                                  </legend>
                                  <div class="mx-4 mt-2">
                                    <div class="flex items-top">
                                      <input
                                        type="checkbox"
                                        formControlName="lunch_day1"
                                        id="lunch_day1"
                                        class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                      <label
                                        for="lunch_day1"
                                        class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                        {{ _day1 }}</label
                                      >
                                    </div>

                                    <div class="flex items-top">
                                      <input
                                        type="checkbox"
                                        formControlName="lunch_day2"
                                        id="lunch_day2"
                                        class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                      <label
                                        for="lunch_day2"
                                        class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                        {{ _day2 }}</label
                                      >
                                    </div>
                                  </div>
                                </fieldset>

                                <div class="grid gap-6 mb-6 mt-6">
                                  <div class="flex items-center">
                                    <input
                                      id="attendance_event"
                                      formControlName="attendance_event"
                                      type="checkbox"
                                      class="form-check-input" />
                                    <label for="attendance_event" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >Yes, I would like to attend the „France“ evening at the {{ _event_space }} on
                                      {{ _event_date }}</label
                                    >
                                  </div>
                                  <div class="flex items-center">
                                    <input
                                      id="hotel_stay_night"
                                      formControlName="hotel_stay_night"
                                      type="checkbox"
                                      class="form-check-input" />
                                    <label for="hotel_stay_night" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >Yes, I would like Atout France to book a room in my name for the night of {{ _book_night }} <br />at
                                      the {{ __txt_Hotel_Night }}
                                    </label>
                                  </div>
                                </div>

                                <fieldset class="">
                                  <input
                                    id="transfer_arrival"
                                    formControlName="transfer_arrival"
                                    type="checkbox"
                                    class="form-check-input" />
                                  &nbsp;
                                  <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                    Yes, I would like Atout France to book a
                                  </legend>
                                  <div class="mx-4 mt-2">
                                    <div class="form-group">
                                      <div class="flex items-center">
                                        <input
                                          id="fransfer_from"
                                          formControlName="transfer_type"
                                          type="radio"
                                          value="Train"
                                          checked
                                          class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                        <label for="fransfer_from" class="ml-3 block text-sm font-medium text-gray-700">Train</label>
                                      </div>

                                      <div class="flex items-center">
                                        <input
                                          id="fransfer_from"
                                          formControlName="transfer_type"
                                          type="radio"
                                          value="Flight"
                                          class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                        <label for="fransfer_from" class="ml-3 block text-sm font-medium text-gray-700">Flight</label>
                                      </div>

                                      <div class="flex items-center">
                                        <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                          from (please specify train station or airport)
                                        </legend>
                                      </div>
                                      <div class="flex items-center">
                                        <input
                                          type="text"
                                          id="fransfer_from"
                                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/3 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                          placeholder=""
                                          formControlName="fransfer_from" />
                                      </div>
                                      <div class="flex items-center">
                                        <label for="fransfer_from" class="ml-3 block text-sm font-medium text-gray-700">
                                          to <b>{{ __txt_Event_City }}</b> on {{ _day1 }}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </fieldset>

                                <fieldset class="mt-4">
                                  <input id="transfer_return" formControlName="transfer_return" type="checkbox" class="form-check-input" />
                                  <label for="hotel_stay_night" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                    Yes, I would like to book the return transfer back from {{ __txt_Event_City }} on {{ _day2 }} <br />
                                    to same as above specified (or in special wishes) <br />
                                  </label>
                                </fieldset>

                                <div class="row mb-6 mt-6">
                                  <label for="special_wishes" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >Special Wishes (diet, twin beds, information on arrival or return ...)</label
                                  >
                                  <textarea
                                    id="special_wishes"
                                    formControlName="special_wishes"
                                    rows="4"
                                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder=""></textarea>
                                </div>
                                <p>&nbsp;</p>
                              </div>
                              <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                              <button mat-raised-button matStepperNext color="primary">Next</button>
                            </div>
                          </div>
                        </div>
                      </mat-step>
                    </ng-template>
                  </ng-container>

                  <ng-template #countrySelection>
                    <mat-step label="Austria" *ngIf="_country == 'at'">
                      <div class="step-container d-flex align-items-start flex-column">
                        <div class="container-fluid">
                          <fieldset class="mt-6 mb-6" *ngIf="showDays">
                            <legend class="contents text-sm font-semibold leading-6 text-gray-900">
                              Which Days will you attend? (if you wish to attend on both days, please check both boxes)
                            </legend>
                            <div class="mx-4 mt-2">
                              <div class="flex items-top">
                                <input
                                  type="checkbox"
                                  formControlName="chkcity1"
                                  id="chkcity1"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label for="chkcity1" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                  {{ _day1 }}</label
                                >
                              </div>

                              <div class="flex items-top">
                                <input
                                  type="checkbox"
                                  formControlName="chkcity2"
                                  id="chkcity2"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label for="chkcity2" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                  {{ _day2 }}</label
                                >
                              </div>
                            </div>
                          </fieldset>

                          <div class="form">
                            <div class="row">
                              <fieldset class="">
                                <legend class="contents text-sm font-semibold leading-6 text-gray-900 mt-4">
                                  Please let us know what you plan to do (all these services are included in your participation package):
                                </legend>
                                <br /><br />
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">Yes, {{ __hotel_parking }}</legend>
                                <div class="mx-4 mt-2">
                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="garage_day1"
                                      id="garage_day1"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="garage_day1"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day1 }}</label
                                    >
                                  </div>

                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="garage_day2"
                                      id="garage_day2"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="garage_day2"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day2 }}</label
                                    >
                                  </div>
                                </div>
                              </fieldset>

                              <fieldset class="">
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like to attend the lunch at the {{ _hotel_info }} on
                                </legend>
                                <div class="mx-4 mt-2">
                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="lunch_day1"
                                      id="lunch_day1"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="lunch_day1"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day1 }}</label
                                    >
                                  </div>

                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="lunch_day2"
                                      id="lunch_day2"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="lunch_day2"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day2 }}</label
                                    >
                                  </div>
                                </div>
                              </fieldset>

                              <div class="grid gap-6 mb-6 mt-6">
                                <div class="flex items-center">
                                  <input
                                    id="attendance_event"
                                    formControlName="attendance_event"
                                    type="checkbox"
                                    class="form-check-input" />
                                  <label for="attendance_event" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like to attend the „France“ evening at the {{ _event_space }} on {{ _event_date }}</label
                                  >
                                </div>
                                <div class="flex items-center">
                                  <input
                                    id="hotel_stay_night"
                                    formControlName="hotel_stay_night"
                                    type="checkbox"
                                    class="form-check-input" />
                                  <label for="hotel_stay_night" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >For visitors, who are not living in {{ __txt_Event_City }}: <br />
                                    Yes, I would like Atout France to book a room in my name for the night of {{ _book_night }} <br />at the
                                    {{ __txt_Hotel_Night }}
                                  </label>
                                </div>
                              </div>

                              <div class="row mb-6 mt-6">
                                <label for="special_wishes" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                  >Special Wishes (diet, twin beds, ...)</label
                                >
                                <textarea
                                  id="special_wishes"
                                  formControlName="special_wishes"
                                  rows="4"
                                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder=""></textarea>
                              </div>
                              <p>&nbsp;</p>
                            </div>
                            <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                            <button mat-raised-button matStepperNext color="primary">Next</button>
                          </div>
                        </div>
                      </div>
                    </mat-step>

                    <mat-step label="Country" *ngIf="_country == 'sk' || _country == 'de' || _country == 'ch' || _country == 'si'">
                      <div class="step-container d-flex align-items-start flex-column">
                        <div class="container-fluid">
                          <fieldset class="mt-6 mb-6" *ngIf="showDays">
                            <legend class="contents text-sm font-semibold leading-6 text-gray-900">
                              Which Days will you attend? (if you wish to attend on both days, please check both boxes)
                            </legend>
                            <div class="mx-4 mt-2">
                              <div class="flex items-top">
                                <input
                                  type="checkbox"
                                  formControlName="chkcity1"
                                  id="chkcity1"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label for="chkcity1" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                  {{ _day1 }}</label
                                >
                              </div>

                              <div class="flex items-top">
                                <input
                                  type="checkbox"
                                  formControlName="chkcity2"
                                  id="chkcity2"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label for="chkcity2" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                  {{ _day2 }}</label
                                >
                              </div>
                            </div>
                          </fieldset>

                          <div class="form">
                            <div class="row">
                              <fieldset class="">
                                <legend class="contents text-sm font-semibold leading-6 text-gray-900 mt-4">
                                  Please let us know what you plan to do (all these services are included in your participation package):
                                </legend>
                                <br /><br />
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like Atout France to reserve a parking place in my name for the
                                </legend>
                                <div class="mx-4 mt-2">
                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="garage_day1"
                                      id="garage_day1"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="garage_day1"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day1 }}</label
                                    >
                                  </div>

                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="garage_day2"
                                      id="garage_day2"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="garage_day2"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day2 }}</label
                                    >
                                  </div>
                                </div>
                              </fieldset>

                              <fieldset class="">
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like to attend the lunch at the {{ _hotel_info }} on
                                </legend>
                                <div class="mx-4 mt-2">
                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="lunch_day1"
                                      id="lunch_day1"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="lunch_day1"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day1 }}</label
                                    >
                                  </div>

                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="lunch_day2"
                                      id="lunch_day2"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="lunch_day2"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day2 }}</label
                                    >
                                  </div>
                                </div>
                              </fieldset>

                              <div class="grid gap-6 mb-6 mt-6">
                                <div class="flex items-center">
                                  <input
                                    id="attendance_event"
                                    formControlName="attendance_event"
                                    type="checkbox"
                                    class="form-check-input" />
                                  <label for="attendance_event" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like to attend the „France“ evening at the {{ _event_space }} on {{ _event_date }}</label
                                  >
                                </div>
                                <div class="flex items-center">
                                  <input
                                    id="hotel_stay_night"
                                    formControlName="hotel_stay_night"
                                    type="checkbox"
                                    class="form-check-input" />
                                  <label for="hotel_stay_night" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like Atout France to book a room in my name for the night of {{ _book_night }} <br />at
                                    the {{ _hotel_info }}
                                  </label>
                                </div>
                              </div>

                              <div class="row mb-6 mt-6">
                                <label for="special_wishes" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                  >Special Wishes (diet, twin beds, ...)</label
                                >
                                <textarea
                                  id="special_wishes"
                                  formControlName="special_wishes"
                                  rows="4"
                                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder=""></textarea>
                              </div>
                              <p>&nbsp;</p>
                            </div>
                            <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                            <button mat-raised-button matStepperNext color="primary">Next</button>
                          </div>
                        </div>
                      </div>
                    </mat-step>

                    <mat-step label="Czech Republic" *ngIf="_country == 'cz'">
                      <div class="step-container d-flex align-items-start flex-column">
                        <div class="container-fluid">
                          <fieldset class="mt-6 mb-6" *ngIf="showDays">
                            <legend class="contents text-sm font-semibold leading-6 text-gray-900">
                              Which Days will you attend? (if you wish to attend on both days, please check both boxes)
                            </legend>
                            <div class="mx-4 mt-4">
                              <div class="flex items-top">
                                <input
                                  type="checkbox"
                                  formControlName="chkcity1"
                                  id="chkcity1"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label for="chkcity1" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                  {{ _day1 }}</label
                                >
                              </div>

                              <div class="flex items-top">
                                <input
                                  type="checkbox"
                                  formControlName="chkcity2"
                                  id="chkcity2"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label for="chkcity2" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                  {{ _day2 }}</label
                                >
                              </div>
                            </div>
                          </fieldset>

                          <div class="form">
                            <div class="row">
                              <fieldset class="">
                                <legend class="contents text-sm font-semibold leading-6 text-gray-900 mt-4">
                                  Please let us know what you plan to do (all these services are included in your participation package):
                                </legend>
                                <br /><br />
                                <input id="transfer_arrival" formControlName="transfer_arrival" type="checkbox" class="form-check-input" />
                                &nbsp;
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like to book the bus transfer to Vienna on {{ _day1 }} from
                                </legend>
                                <div class="mx-4 mt-2">
                                  <div class="form-group">
                                    <div class="flex items-center">
                                      <input
                                        id="fransfer_from"
                                        formControlName="fransfer_from"
                                        type="radio"
                                        value="Prague"
                                        checked
                                        class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                      <label for="fransfer_from" class="ml-3 block text-sm font-medium text-gray-700">Prague</label>
                                    </div>

                                    <div class="flex items-center">
                                      <input
                                        id="fransfer_from"
                                        formControlName="fransfer_from"
                                        type="radio"
                                        value="Plzen"
                                        class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                      <label for="fransfer_from" class="ml-3 block text-sm font-medium text-gray-700">Plzeň</label>
                                    </div>
                                    <div class="flex items-center">
                                      <input
                                        id="fransfer_from"
                                        formControlName="fransfer_from"
                                        type="radio"
                                        value="Brno"
                                        class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                      <label for="fransfer_from" class="ml-3 block text-sm font-medium text-gray-700">Brno</label>
                                    </div>
                                  </div>
                                </div>
                              </fieldset>

                              <div class="grid gap-6 mb-6 mt-6">
                                <div class="flex items-center">
                                  <input id="transfer_return" formControlName="transfer_return" type="checkbox" class="form-check-input" />
                                  <label for="transfer_return" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like to book the return transfer from Vienna on {{ _day2 }}
                                  </label>
                                </div>
                              </div>

                              <fieldset class="">
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like Atout France to reserve a parking place in my name for the
                                </legend>
                                <div class="mx-4 mt-2">
                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="garage_day1"
                                      id="garage_day1"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="garage_day1"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day1 }}</label
                                    >
                                  </div>

                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="garage_day2"
                                      id="garage_day2"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="garage_day2"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day2 }}</label
                                    >
                                  </div>
                                </div>
                              </fieldset>

                              <fieldset class="">
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like to attend the lunch at the {{ _hotel_info }} on
                                </legend>
                                <div class="mx-4 mt-2">
                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="lunch_day1"
                                      id="lunch_day1"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="lunch_day1"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day1 }}</label
                                    >
                                  </div>

                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="lunch_day2"
                                      id="lunch_day2"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="lunch_day2"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day2 }}</label
                                    >
                                  </div>
                                </div>
                              </fieldset>

                              <div class="grid gap-6 mb-6 mt-6">
                                <div class="flex items-center">
                                  <input
                                    id="attendance_event"
                                    formControlName="attendance_event"
                                    type="checkbox"
                                    class="form-check-input" />
                                  <label for="attendance_event" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like to attend the „France“ evening at the {{ _event_space }} on {{ _event_date }}</label
                                  >
                                </div>
                                <div class="flex items-center">
                                  <input
                                    id="hotel_stay_night"
                                    formControlName="hotel_stay_night"
                                    type="checkbox"
                                    class="form-check-input" />
                                  <label for="hotel_stay_night" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like Atout France to book a room in my name for the night of {{ _book_night }} <br />at
                                    the {{ _hotel_info }}
                                  </label>
                                </div>
                              </div>

                              <div class="row mb-6 mt-6">
                                <label for="special_wishes" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                  >Special Wishes (diet, twin beds, ...)</label
                                >
                                <textarea
                                  id="special_wishes"
                                  formControlName="special_wishes"
                                  rows="4"
                                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder=""></textarea>
                              </div>
                              <p>&nbsp;</p>
                            </div>
                            <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                            <button mat-raised-button matStepperNext color="primary">Next</button>
                          </div>
                        </div>
                      </div>
                    </mat-step>

                    <mat-step label="Hungary" *ngIf="_country == 'hu'">
                      <div class="step-container d-flex align-items-start flex-column">
                        <div class="container-fluid">
                          <fieldset class="mt-6 mb-6" *ngIf="showDays">
                            <legend class="contents text-sm font-semibold leading-6 text-gray-900">
                              Which Days will you attend? (if you wish to attend on both days, please check both boxes)
                            </legend>
                            <div class="mx-4 mt-4">
                              <div class="flex items-top">
                                <input
                                  type="checkbox"
                                  formControlName="chkcity1"
                                  id="chkcity1"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label for="chkcity1" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                  {{ _day1 }}</label
                                >
                              </div>

                              <div class="flex items-top">
                                <input
                                  type="checkbox"
                                  formControlName="chkcity2"
                                  id="chkcity2"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label for="chkcity2" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                  {{ _day2 }}</label
                                >
                              </div>
                            </div>
                          </fieldset>

                          <div class="form">
                            <div class="row">
                              <fieldset class="">
                                <legend class="contents text-sm font-semibold leading-6 text-gray-900 mt-4">
                                  Please let us know what you plan to do (all these services are included in your participation package):
                                </legend>
                                <br /><br />
                                <input id="transfer_arrival" formControlName="transfer_arrival" type="checkbox" class="form-check-input" />
                                &nbsp;
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like to book the bus transfer from Budapest to Vienna on {{ _day1 }}
                                </legend>
                              </fieldset>

                              <div class="grid gap-6 mb-6 mt-6">
                                <div class="flex items-center">
                                  <input id="transfer_return" formControlName="transfer_return" type="checkbox" class="form-check-input" />
                                  <label for="transfer_return" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like to book the return transfer from Vienna on {{ _day2 }}
                                  </label>
                                </div>
                              </div>

                              <fieldset class="">
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like Atout France to reserve a parking place in my name for the
                                </legend>
                                <div class="mx-4 mt-2">
                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="garage_day1"
                                      id="garage_day1"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="garage_day1"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day1 }}</label
                                    >
                                  </div>

                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="garage_day2"
                                      id="garage_day2"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="garage_day2"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day2 }}</label
                                    >
                                  </div>
                                </div>
                              </fieldset>

                              <fieldset class="">
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like to attend the lunch at the {{ _hotel_info }} on
                                </legend>
                                <div class="mx-4 mt-2">
                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="lunch_day1"
                                      id="lunch_day1"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="lunch_day1"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day1 }}</label
                                    >
                                  </div>

                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="lunch_day2"
                                      id="lunch_day2"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="lunch_day2"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day2 }}</label
                                    >
                                  </div>
                                </div>
                              </fieldset>

                              <div class="grid gap-6 mb-6 mt-6">
                                <div class="flex items-center">
                                  <input
                                    id="attendance_event"
                                    formControlName="attendance_event"
                                    type="checkbox"
                                    class="form-check-input" />
                                  <label for="attendance_event" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like to attend the „France“ evening at the {{ _event_space }} on {{ _event_date }}</label
                                  >
                                </div>
                                <div class="flex items-center">
                                  <input
                                    id="hotel_stay_night"
                                    formControlName="hotel_stay_night"
                                    type="checkbox"
                                    class="form-check-input" />
                                  <label for="hotel_stay_night" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like Atout France to book a room in my name for the night of {{ _book_night }} <br />at
                                    the {{ _hotel_info }}
                                  </label>
                                </div>
                              </div>

                              <div class="row mb-6 mt-6">
                                <label for="special_wishes" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                  >Special Wishes (diet, twin beds, ...)</label
                                >
                                <textarea
                                  id="special_wishes"
                                  formControlName="special_wishes"
                                  rows="4"
                                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder=""></textarea>
                              </div>
                              <p>&nbsp;</p>
                            </div>
                            <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                            <button mat-raised-button matStepperNext color="primary">Next</button>
                          </div>
                        </div>
                      </div>
                    </mat-step>

                    <mat-step label="Bulgaria" *ngIf="_country == 'bg'">
                      <div class="step-container d-flex align-items-start flex-column">
                        <div class="container-fluid">
                          <fieldset class="mt-6 mb-6" *ngIf="showDays">
                            <legend class="contents text-sm font-semibold leading-6 text-gray-900">
                              Which Days will you attend? (if you wish to attend on both days, please check both boxes)
                            </legend>
                            <div class="mx-4 mt-4">
                              <div class="flex items-top">
                                <input
                                  type="checkbox"
                                  formControlName="chkcity1"
                                  id="chkcity1"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label for="chkcity1" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                  {{ _day1 }}</label
                                >
                              </div>

                              <div class="flex items-top">
                                <input
                                  type="checkbox"
                                  formControlName="chkcity2"
                                  id="chkcity2"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label for="chkcity2" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                  {{ _day2 }}</label
                                >
                              </div>
                            </div>
                          </fieldset>

                          <div class="form">
                            <div class="row">
                              <fieldset class="">
                                <legend class="contents text-sm font-semibold leading-6 text-gray-900 mt-4">
                                  Please let us know what you plan to do (all these services are included in your participation package):
                                </legend>
                                <br /><br />
                                <input id="transfer_arrival" formControlName="transfer_arrival" type="checkbox" class="form-check-input" />
                                &nbsp;
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like to book the flight transfer from Sofia to Vienna on {{ _day1 }}
                                </legend>
                              </fieldset>

                              <div class="grid gap-6 mb-6 mt-6">
                                <div class="flex items-center">
                                  <input id="transfer_return" formControlName="transfer_return" type="checkbox" class="form-check-input" />
                                  <label for="transfer_return" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like to book the flight transfer back to Sofia on {{ _day2 }}
                                  </label>
                                </div>
                              </div>

                              <fieldset class="">
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like to attend the lunch at the {{ _hotel_info }} on
                                </legend>
                                <div class="mx-4 mt-2">
                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="lunch_day1"
                                      id="lunch_day1"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="lunch_day1"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day1 }}</label
                                    >
                                  </div>

                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="lunch_day2"
                                      id="lunch_day2"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="lunch_day2"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day2 }}</label
                                    >
                                  </div>
                                </div>
                              </fieldset>

                              <div class="grid gap-6 mb-6 mt-6">
                                <div class="flex items-center">
                                  <input
                                    id="attendance_event"
                                    formControlName="attendance_event"
                                    type="checkbox"
                                    class="form-check-input" />
                                  <label for="attendance_event" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like to attend the „France“ evening at the {{ _event_space }} on {{ _event_date }}</label
                                  >
                                </div>
                                <div class="flex items-center">
                                  <input
                                    id="hotel_stay_night"
                                    formControlName="hotel_stay_night"
                                    type="checkbox"
                                    class="form-check-input" />
                                  <label for="hotel_stay_night" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like Atout France to book a room in my name for the night of {{ _book_night }} <br />at
                                    the {{ _hotel_info }}
                                  </label>
                                </div>
                              </div>

                              <div class="row mb-6 mt-6">
                                <label for="special_wishes" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                  >Special Wishes (diet, twin beds, ...)</label
                                >
                                <textarea
                                  id="special_wishes"
                                  formControlName="special_wishes"
                                  rows="4"
                                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder=""></textarea>
                              </div>
                              <p>&nbsp;</p>
                            </div>
                            <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                            <button mat-raised-button matStepperNext color="primary">Next</button>
                          </div>
                        </div>
                      </div>
                    </mat-step>

                    <mat-step label="Croatia" *ngIf="_country == 'hr'">
                      <div class="step-container d-flex align-items-start flex-column">
                        <div class="container-fluid">
                          <fieldset class="mt-6 mb-6" *ngIf="showDays">
                            <legend class="contents text-sm font-semibold leading-6 text-gray-900">
                              Which Days will you attend? (if you wish to attend on both days, please check both boxes)
                            </legend>
                            <div class="mx-4 mt-4">
                              <div class="flex items-top">
                                <input
                                  type="checkbox"
                                  formControlName="chkcity1"
                                  id="chkcity1"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label for="chkcity1" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                  {{ _day1 }}</label
                                >
                              </div>

                              <div class="flex items-top">
                                <input
                                  type="checkbox"
                                  formControlName="chkcity2"
                                  id="chkcity2"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label for="chkcity2" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                  {{ _day2 }}</label
                                >
                              </div>
                            </div>
                          </fieldset>

                          <div class="form">
                            <div class="row">
                              <fieldset class="">
                                <legend class="contents text-sm font-semibold leading-6 text-gray-900 mt-4">
                                  Please let us know what you plan to do (all these services are included in your participation package):
                                </legend>
                                <br /><br />
                                <input id="transfer_arrival" formControlName="transfer_arrival" type="checkbox" class="form-check-input" />
                                &nbsp;
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like to book the flight transfer from Zagreb to Vienna on {{ _day1 }}
                                </legend>
                              </fieldset>

                              <div class="grid gap-6 mb-6 mt-6">
                                <div class="flex items-center">
                                  <input id="transfer_return" formControlName="transfer_return" type="checkbox" class="form-check-input" />
                                  <label for="transfer_return" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like to book the flight transfer back to Zagreb on {{ _day2 }}
                                  </label>
                                </div>
                              </div>

                              <fieldset class="">
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like Atout France to reserve a parking place in my name for the
                                </legend>
                                <div class="mx-4 mt-2">
                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="garage_day1"
                                      id="garage_day1"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="garage_day1"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day1 }}</label
                                    >
                                  </div>

                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="garage_day2"
                                      id="garage_day2"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="garage_day2"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day2 }}</label
                                    >
                                  </div>
                                </div>
                              </fieldset>

                              <fieldset class="">
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like to attend the lunch at the {{ _hotel_info }} on
                                </legend>
                                <div class="mx-4 mt-2">
                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="lunch_day1"
                                      id="lunch_day1"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="lunch_day1"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day1 }}</label
                                    >
                                  </div>

                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="lunch_day2"
                                      id="lunch_day2"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="lunch_day2"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day2 }}</label
                                    >
                                  </div>
                                </div>
                              </fieldset>

                              <div class="grid gap-6 mb-6 mt-6">
                                <div class="flex items-center">
                                  <input
                                    id="attendance_event"
                                    formControlName="attendance_event"
                                    type="checkbox"
                                    class="form-check-input" />
                                  <label for="attendance_event" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like to attend the „France“ evening at the {{ _event_space }} on {{ _event_date }}</label
                                  >
                                </div>
                                <div class="flex items-center">
                                  <input
                                    id="hotel_stay_night"
                                    formControlName="hotel_stay_night"
                                    type="checkbox"
                                    class="form-check-input" />
                                  <label for="hotel_stay_night" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like Atout France to book a room in my name for the night of {{ _book_night }} <br />at
                                    the {{ _hotel_info }}
                                  </label>
                                </div>
                              </div>

                              <div class="row mb-6 mt-6">
                                <label for="special_wishes" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                  >Special Wishes (diet, twin beds, ...)</label
                                >
                                <textarea
                                  id="special_wishes"
                                  formControlName="special_wishes"
                                  rows="4"
                                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder=""></textarea>
                              </div>
                              <p>&nbsp;</p>
                            </div>
                            <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                            <button mat-raised-button matStepperNext color="primary">Next</button>
                          </div>
                        </div>
                      </div>
                    </mat-step>

                    <mat-step label="Poland" *ngIf="_country == 'pl'">
                      <div class="step-container d-flex align-items-start flex-column">
                        <div class="container-fluid">
                          <fieldset class="mt-6 mb-6" *ngIf="showDays">
                            <legend class="contents text-sm font-semibold leading-6 text-gray-900">
                              Which Days will you attend? (if you wish to attend on both days, please check both boxes)
                            </legend>
                            <div class="mx-4 mt-4">
                              <div class="flex items-top">
                                <input
                                  type="checkbox"
                                  formControlName="chkcity1"
                                  id="chkcity1"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label for="chkcity1" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                  {{ _day1 }}</label
                                >
                              </div>

                              <div class="flex items-top">
                                <input
                                  type="checkbox"
                                  formControlName="chkcity2"
                                  id="chkcity2"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label for="chkcity2" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                  {{ _day2 }}</label
                                >
                              </div>
                            </div>
                          </fieldset>

                          <div class="form">
                            <div class="row">
                              <fieldset class="">
                                <legend class="contents text-sm font-semibold leading-6 text-gray-900 mt-4">
                                  Please let us know what you plan to do (all these services are included in your participation package):
                                </legend>
                                <br /><br />
                                <input id="transfer_arrival" formControlName="transfer_arrival" type="checkbox" class="form-check-input" />
                                &nbsp;
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like to book the flight transfer from Poland to Vienna on {{ _day1 }}
                                </legend>

                                <br />Please specify the departure airport:

                                <input
                                  type="text"
                                  id="fransfer_from"
                                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder=""
                                  formControlName="fransfer_from" />
                              </fieldset>

                              <div class="grid gap-6 mb-6 mt-6">
                                <div class="flex items-center">
                                  <input id="transfer_return" formControlName="transfer_return" type="checkbox" class="form-check-input" />
                                  <label for="transfer_return" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like to book the flight transfer back to Poland on {{ _day2 }}
                                  </label>
                                </div>
                              </div>

                              <fieldset class="">
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like Atout France to reserve a parking place in my name for the
                                </legend>
                                <div class="mx-4 mt-2">
                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="garage_day1"
                                      id="garage_day1"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="garage_day1"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day1 }}</label
                                    >
                                  </div>

                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="garage_day2"
                                      id="garage_day2"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="garage_day2"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day2 }}</label
                                    >
                                  </div>
                                </div>
                              </fieldset>

                              <fieldset class="">
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like to attend the lunch at the {{ _hotel_info }} on
                                </legend>
                                <div class="mx-4 mt-2">
                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="lunch_day1"
                                      id="lunch_day1"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="lunch_day1"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day1 }}</label
                                    >
                                  </div>

                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="lunch_day2"
                                      id="lunch_day2"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="lunch_day2"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day2 }}</label
                                    >
                                  </div>
                                </div>
                              </fieldset>

                              <div class="grid gap-6 mb-6 mt-6">
                                <div class="flex items-center">
                                  <input
                                    id="attendance_event"
                                    formControlName="attendance_event"
                                    type="checkbox"
                                    class="form-check-input" />
                                  <label for="attendance_event" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like to attend the „France“ evening at the {{ _event_space }} on {{ _event_date }}</label
                                  >
                                </div>
                                <div class="flex items-center">
                                  <input
                                    id="hotel_stay_night"
                                    formControlName="hotel_stay_night"
                                    type="checkbox"
                                    class="form-check-input" />
                                  <label for="hotel_stay_night" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like Atout France to book a room in my name for the night of {{ _book_night }} <br />at
                                    the {{ _hotel_info }}
                                  </label>
                                </div>
                              </div>

                              <div class="row mb-6 mt-6">
                                <label for="special_wishes" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                  >Special Wishes (diet, twin beds, ...)</label
                                >
                                <textarea
                                  id="special_wishes"
                                  formControlName="special_wishes"
                                  rows="4"
                                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder=""></textarea>
                              </div>
                              <p>&nbsp;</p>
                            </div>
                            <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                            <button mat-raised-button matStepperNext color="primary">Next</button>
                          </div>
                        </div>
                      </div>
                    </mat-step>

                    <mat-step label="Romania" *ngIf="_country == 'ro'">
                      <div class="step-container d-flex align-items-start flex-column">
                        <div class="container-fluid">
                          <fieldset class="mt-6 mb-6" *ngIf="showDays">
                            <legend class="contents text-sm font-semibold leading-6 text-gray-900">
                              Which Days will you attend? (if you wish to attend on both days, please check both boxes)
                            </legend>
                            <div class="mx-4 mt-4">
                              <div class="flex items-top">
                                <input
                                  type="checkbox"
                                  formControlName="chkcity1"
                                  id="chkcity1"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label for="chkcity1" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                  {{ _day1 }}</label
                                >
                              </div>

                              <div class="flex items-top">
                                <input
                                  type="checkbox"
                                  formControlName="chkcity2"
                                  id="chkcity2"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label for="chkcity2" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                  {{ _day2 }}</label
                                >
                              </div>
                            </div>
                          </fieldset>

                          <div class="form">
                            <div class="row">
                              <fieldset class="">
                                <legend class="contents text-sm font-semibold leading-6 text-gray-900 mt-4">
                                  Please let us know what you plan to do (all these services are included in your participation package):
                                </legend>
                                <br /><br />
                                <input id="transfer_arrival" formControlName="transfer_arrival" type="checkbox" class="form-check-input" />
                                &nbsp;
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like to book the flight transfer from Bucharest to Vienna on {{ _day1 }}
                                </legend>
                              </fieldset>

                              <div class="grid gap-6 mb-6 mt-6">
                                <div class="flex items-center">
                                  <input id="transfer_return" formControlName="transfer_return" type="checkbox" class="form-check-input" />
                                  <label for="transfer_return" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like to book the flight transfer back to Bucharest on {{ _day2 }}
                                  </label>
                                </div>
                              </div>

                              <fieldset class="">
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like to attend the lunch at the {{ _hotel_info }} on
                                </legend>
                                <div class="mx-4 mt-2">
                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="lunch_day1"
                                      id="lunch_day1"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="lunch_day1"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day1 }}</label
                                    >
                                  </div>

                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="lunch_day2"
                                      id="lunch_day2"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="lunch_day2"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day2 }}</label
                                    >
                                  </div>
                                </div>
                              </fieldset>

                              <div class="grid gap-6 mb-6 mt-6">
                                <div class="flex items-center">
                                  <input
                                    id="attendance_event"
                                    formControlName="attendance_event"
                                    type="checkbox"
                                    class="form-check-input" />
                                  <label for="attendance_event" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like to attend the „France“ evening at the {{ _event_space }} on {{ _event_date }}</label
                                  >
                                </div>
                                <div class="flex items-center">
                                  <input
                                    id="hotel_stay_night"
                                    formControlName="hotel_stay_night"
                                    type="checkbox"
                                    class="form-check-input" />
                                  <label for="hotel_stay_night" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like Atout France to book a room in my name for the night of {{ _book_night }} <br />at
                                    the {{ _hotel_info }}
                                  </label>
                                </div>
                              </div>

                              <div class="row mb-6 mt-6">
                                <label for="special_wishes" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                  >Special Wishes (diet, twin beds, ...)</label
                                >
                                <textarea
                                  id="special_wishes"
                                  formControlName="special_wishes"
                                  rows="4"
                                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder=""></textarea>
                              </div>
                              <p>&nbsp;</p>
                            </div>
                            <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                            <button mat-raised-button matStepperNext color="primary">Next</button>
                          </div>
                        </div>
                      </div>
                    </mat-step>

                    <mat-step label="Serbia" *ngIf="_country == 'xs'">
                      <div class="step-container d-flex align-items-start flex-column">
                        <div class="container-fluid">
                          <fieldset class="mt-6 mb-6" *ngIf="showDays">
                            <legend class="contents text-sm font-semibold leading-6 text-gray-900">
                              Which Days will you attend? (if you wish to attend on both days, please check both boxes)
                            </legend>
                            <div class="mx-4 mt-4">
                              <div class="flex items-top">
                                <input
                                  type="checkbox"
                                  formControlName="chkcity1"
                                  id="chkcity1"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label for="chkcity1" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                  {{ _day1 }}</label
                                >
                              </div>

                              <div class="flex items-top">
                                <input
                                  type="checkbox"
                                  formControlName="chkcity2"
                                  id="chkcity2"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label for="chkcity2" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                  {{ _day2 }}</label
                                >
                              </div>
                            </div>
                          </fieldset>

                          <div class="form">
                            <div class="row">
                              <fieldset class="">
                                <legend class="contents text-sm font-semibold leading-6 text-gray-900 mt-4">
                                  Please let us know what you plan to do (all these services are included in your participation package):
                                </legend>
                                <br /><br />
                                <input id="transfer_arrival" formControlName="transfer_arrival" type="checkbox" class="form-check-input" />
                                &nbsp;
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like to book the flight transfer from Belgrade to Vienna on {{ _day1 }}
                                </legend>
                              </fieldset>

                              <div class="grid gap-6 mb-6 mt-6">
                                <div class="flex items-center">
                                  <input id="transfer_return" formControlName="transfer_return" type="checkbox" class="form-check-input" />
                                  <label for="transfer_return" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like to book the flight transfer back to Belgrade on {{ _day2 }}
                                  </label>
                                </div>
                              </div>

                              <fieldset class="">
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like to attend the lunch at the {{ _hotel_info }} on
                                </legend>
                                <div class="mx-4 mt-2">
                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="lunch_day1"
                                      id="lunch_day1"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="lunch_day1"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day1 }}</label
                                    >
                                  </div>

                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="lunch_day2"
                                      id="lunch_day2"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="lunch_day2"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day2 }}</label
                                    >
                                  </div>
                                </div>
                              </fieldset>

                              <div class="grid gap-6 mb-6 mt-6">
                                <div class="flex items-center">
                                  <input
                                    id="attendance_event"
                                    formControlName="attendance_event"
                                    type="checkbox"
                                    class="form-check-input" />
                                  <label for="attendance_event" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like to attend the „France“ evening at the {{ _event_space }} on {{ _event_date }}</label
                                  >
                                </div>
                                <div class="flex items-center">
                                  <input
                                    id="hotel_stay_night"
                                    formControlName="hotel_stay_night"
                                    type="checkbox"
                                    class="form-check-input" />
                                  <label for="hotel_stay_night" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like Atout France to book a room in my name for the night of {{ _book_night }} <br />at
                                    the {{ _hotel_info }}
                                  </label>
                                </div>
                              </div>

                              <div class="row mb-6 mt-6">
                                <label for="special_wishes" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                  >Special Wishes (diet, twin beds, ...)</label
                                >
                                <textarea
                                  id="special_wishes"
                                  formControlName="special_wishes"
                                  rows="4"
                                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder=""></textarea>
                              </div>
                              <p>&nbsp;</p>
                            </div>
                            <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                            <button mat-raised-button matStepperNext color="primary">Next</button>
                          </div>
                        </div>
                      </div>
                    </mat-step>

                    <mat-step label="Macedonia" *ngIf="_country == 'mk'">
                      <div class="step-container d-flex align-items-start flex-column">
                        <div class="container-fluid">
                          <fieldset class="mt-6 mb-6" *ngIf="showDays">
                            <legend class="contents text-sm font-semibold leading-6 text-gray-900">
                              Which Days will you attend? (if you wish to attend on both days, please check both boxes)
                            </legend>
                            <div class="mx-4 mt-4">
                              <div class="flex items-top">
                                <input
                                  type="checkbox"
                                  formControlName="chkcity1"
                                  id="chkcity1"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label for="chkcity1" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                  {{ _day1 }}</label
                                >
                              </div>

                              <div class="flex items-top">
                                <input
                                  type="checkbox"
                                  formControlName="chkcity2"
                                  id="chkcity2"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label for="chkcity2" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                  {{ _day2 }}</label
                                >
                              </div>
                            </div>
                          </fieldset>

                          <div class="form">
                            <div class="row">
                              <fieldset class="">
                                <legend class="contents text-sm font-semibold leading-6 text-gray-900 mt-4">
                                  Please let us know what you plan to do (all these services are included in your participation package):
                                </legend>
                                <br /><br />
                                <input id="transfer_arrival" formControlName="transfer_arrival" type="checkbox" class="form-check-input" />
                                &nbsp;
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like to book the flight transfer from Skopje to Vienna on {{ _day1 }}
                                </legend>
                              </fieldset>

                              <div class="grid gap-6 mb-6 mt-6">
                                <div class="flex items-center">
                                  <input id="transfer_return" formControlName="transfer_return" type="checkbox" class="form-check-input" />
                                  <label for="transfer_return" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like to book the flight transfer back to Skopje on {{ _day2 }}
                                  </label>
                                </div>
                              </div>

                              <fieldset class="">
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like to attend the lunch at the {{ _hotel_info }} on
                                </legend>
                                <div class="mx-4 mt-2">
                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="lunch_day1"
                                      id="lunch_day1"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="lunch_day1"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day1 }}</label
                                    >
                                  </div>

                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="lunch_day2"
                                      id="lunch_day2"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="lunch_day2"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day2 }}</label
                                    >
                                  </div>
                                </div>
                              </fieldset>

                              <div class="grid gap-6 mb-6 mt-6">
                                <div class="flex items-center">
                                  <input
                                    id="attendance_event"
                                    formControlName="attendance_event"
                                    type="checkbox"
                                    class="form-check-input" />
                                  <label for="attendance_event" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like to attend the „France“ evening at the {{ _event_space }} on {{ _event_date }}</label
                                  >
                                </div>
                                <div class="flex items-center">
                                  <input
                                    id="hotel_stay_night"
                                    formControlName="hotel_stay_night"
                                    type="checkbox"
                                    class="form-check-input" />
                                  <label for="hotel_stay_night" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like Atout France to book a room in my name for the night of {{ _book_night }} <br />at
                                    the {{ _hotel_info }}
                                  </label>
                                </div>
                              </div>

                              <div class="row mb-6 mt-6">
                                <label for="special_wishes" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                  >Special Wishes (diet, twin beds, ...)</label
                                >
                                <textarea
                                  id="special_wishes"
                                  formControlName="special_wishes"
                                  rows="4"
                                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder=""></textarea>
                              </div>
                              <p>&nbsp;</p>
                            </div>
                            <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                            <button mat-raised-button matStepperNext color="primary">Next</button>
                          </div>
                        </div>
                      </div>
                    </mat-step>

                    <mat-step label="Country" *ngIf="_country == 'ba' || _country == 'xk' || _country == 'mne'">
                      <div class="step-container d-flex align-items-start flex-column">
                        <div class="container-fluid">
                          <fieldset class="mt-6 mb-6" *ngIf="showDays">
                            <legend class="contents text-sm font-semibold leading-6 text-gray-900">
                              Which Days will you attend? (if you wish to attend on both days, please check both boxes)
                            </legend>
                            <div class="mx-4 mt-4">
                              <div class="flex items-top">
                                <input
                                  type="checkbox"
                                  formControlName="chkcity1"
                                  id="chkcity1"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label for="chkcity1" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                  {{ _day1 }}</label
                                >
                              </div>

                              <div class="flex items-top">
                                <input
                                  type="checkbox"
                                  formControlName="chkcity2"
                                  id="chkcity2"
                                  class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                <label for="chkcity2" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                  {{ _day2 }}</label
                                >
                              </div>
                            </div>
                          </fieldset>

                          <div class="form">
                            <div class="row">
                              <fieldset class="">
                                <legend class="contents text-sm font-semibold leading-6 text-gray-900 mt-4">
                                  Please let us know what you plan to do (all these services are included in your participation package):
                                </legend>
                                <br /><br />
                                <input id="transfer_arrival" formControlName="transfer_arrival" type="checkbox" class="form-check-input" />
                                &nbsp;
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like to book the flight transfer from Sarajevo to Vienna on {{ _day1 }}
                                </legend>
                              </fieldset>

                              <div class="grid gap-6 mb-6 mt-6">
                                <div class="flex items-center">
                                  <input id="transfer_return" formControlName="transfer_return" type="checkbox" class="form-check-input" />
                                  <label for="transfer_return" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like to book the flight transfer back to Sarajevo on {{ _day2 }}
                                  </label>
                                </div>
                              </div>

                              <fieldset class="">
                                <legend class="contents text-sm leading-6 font-medium text-gray-900">
                                  Yes, I would like to attend the lunch at the {{ _hotel_info }} on
                                </legend>
                                <div class="mx-4 mt-2">
                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="lunch_day1"
                                      id="lunch_day1"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="lunch_day1"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day1 }}</label
                                    >
                                  </div>

                                  <div class="flex items-top">
                                    <input
                                      type="checkbox"
                                      formControlName="lunch_day2"
                                      id="lunch_day2"
                                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                    <label
                                      for="lunch_day2"
                                      class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                      {{ _day2 }}</label
                                    >
                                  </div>
                                </div>
                              </fieldset>

                              <div class="grid gap-6 mb-6 mt-6">
                                <div class="flex items-center">
                                  <input
                                    id="attendance_event"
                                    formControlName="attendance_event"
                                    type="checkbox"
                                    class="form-check-input" />
                                  <label for="attendance_event" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like to attend the „France“ evening at the {{ _event_space }} on {{ _event_date }}</label
                                  >
                                </div>
                                <div class="flex items-center">
                                  <input
                                    id="hotel_stay_night"
                                    formControlName="hotel_stay_night"
                                    type="checkbox"
                                    class="form-check-input" />
                                  <label for="hotel_stay_night" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >Yes, I would like Atout France to book a room in my name for the night of {{ _book_night }} <br />at
                                    the {{ _hotel_info }}
                                  </label>
                                </div>
                              </div>

                              <div class="row mb-6 mt-6">
                                <label for="special_wishes" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                  >Special Wishes (diet, twin beds, ...)</label
                                >
                                <textarea
                                  id="special_wishes"
                                  formControlName="special_wishes"
                                  rows="4"
                                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  placeholder=""></textarea>
                              </div>
                              <p>&nbsp;</p>
                            </div>
                            <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                            <button mat-raised-button matStepperNext color="primary">Next</button>
                          </div>
                        </div>
                      </div>
                    </mat-step>
                  </ng-template>

                  <mat-step label="Terms">
                    <div class="step-container d-flex align-items-start flex-column">
                      <div class="container-fluid">
                        <div class="card bg-light mb-3 termsCard">
                          <div class="card-body">
                            <div class="form-group form-check" *ngIf="showTerms">
                              <input type="checkbox" formControlName="agb_accepted" id="agb_accepted" class="form-check-input" />
                              <label for="agb_accepted" class="block mb-2 text-sm text-gray-900 dark:text-white">
                                <div *ngIf="termsListA.includes(_country)">
                                  <span style="font-weight: 500">I agree with the Terms & Conditions</span>: <br />

                                  The invitation is valid for one person from the tour-operator/agency and cannot be transferred to another
                                  person. The participation in the workshop, the evening program and the accommodation at the Bayerischer
                                  Hof Munich***** or another nearby hotel (for buyers who are not living in Munich), are free of charge as
                                  well as the lunch on {{ _day1 }} and on {{ _day2 }} and the transfers to/from the France evening. Once the
                                  online appointment scheduling system is open, the visitors will be able to set up their meeting schedules
                                  with the French partners on the event's dedicated website. A minimum of 8 meetings per visitor is
                                  required.
                                </div>

                                <div *ngIf="termsListB.includes(_country)">
                                  <span style="font-weight: 500">I agree with the Terms & Conditions</span>: <br />

                                  The invitation is valid for one person from the tour-operator/agency and cannot be transferred to another
                                  person. The participation in the workshop, the evening program and the accommodation at the
                                  {{ _hotel_info }} or another nearby hotel are free of charge, as well as the lunch on {{ _day1 }} and on
                                  {{ _day2 }}, the transfers to/from the France evening and the transfers from/to the airport or train
                                  station. <br />

                                  Your participation fee is 195 € plus VAT and represents a flat fee for transport and accomodation. Once
                                  the online appointment scheduling system is open, the visitors will be able to set up their meeting
                                  schedules with the French partners on the event's dedicated website. A minimum of 8 meetings per visitor
                                  is required.
                                </div>

                                <!--
                                <div *ngIf="!termsListA.includes(_country) && !termsListB.includes(_country)">
                                  <span style="font-weight: 500">I agree with the Terms & Conditions</span>: <br />
                                  The invitation is valid for one person from the tour-operator/agency and cannot be transferred to another
                                  person. The participation in the workshop, the evening program and the accommodation at the
                                  {{ _hotel_info }} or another nearby hotel are free of charge, as well as the lunch on {{ _day1 }} and on
                                  {{ _day2 }}, the transfers to/from the France evening and the transfers from/to the airport. The
                                  participation fee for participants arriving by air is 120 € plus VAT and represents a flat fee for air
                                  travel. <br />
                                  Once the online appointment scheduling system is open, the tour-operators will be able to set up their
                                  meeting schedules with the French partners on the event's dedicated website. A minimum of 8 meetings per
                                  visitor is required.
                                </div>
                                -->
                              </label>
                              <div *ngIf="submitted && firstVisitorForm.controls.agb_accepted.invalid" class="error">
                                Accept Terms & Conditions is required
                              </div>
                            </div>

                            <div class="form-group form-check" *ngIf="showStorno">
                              <input type="checkbox" formControlName="storno_accepted" id="storno_accepted" class="form-check-input" />
                              <label for="storno_accepted" class="block mb-2 text-sm text-gray-900 dark:text-white">
                                <div *ngIf="termsListA.includes(_country)">
                                  <span style="font-weight: 500">I agree with the cancellation terms</span>: <br />

                                  The participation to {{ __txt_Event_Name }} {{ _eventYear }} is free of charge. In case of cancellation
                                  after {{ _cancel_date }} OR a no show, a participation of € 195,00 will be charged for those who have
                                  booked the accomodation.
                                </div>

                                <div *ngIf="termsListB.includes(_country)">
                                  <span style="font-weight: 500">I agree with the cancellation terms</span>: <br />

                                  In case of cancellation after the airline or train tickets have been booked and for all cancellations
                                  after {{ _cancel_date }} , the registration fee can no longer be refunded.

                                  <!--
                                  The participation to the {{ __txt_Event_Name }} {{ _eventYear }} is free of charge. In case of unjustified
                                  cancellation after {{ _cancel_date }} OR a no show, a participation of € 120,00 will be charged for those
                                  who have booked the accomodation.
                                  -->
                                </div>

                                <!--
                                <div *ngIf="!termsListA.includes(_country) && !termsListB.includes(_country)">
                                  <span style="font-weight: 500">I agree with the cancellation terms</span>: <br />
                                  In case of cancellation after the airline tickets have been booked and for all cancellations after
                                  {{ _cancel_date }}, the registration fee can no longer be refunded.
                                </div>
                                -->
                              </label>
                              <div *ngIf="submitted && firstVisitorForm.controls.storno_accepted.invalid" class="error">
                                Accept Cancellation Terms is required
                              </div>
                            </div>

                            <div class="form-group form-check" *ngIf="showDSGVO">
                              <input type="checkbox" formControlName="dsgvo_accepted" id="dsgvo_accepted" class="form-check-input" />
                              <label for="dsgvo_accepted" class="block mb-2 text-sm text-gray-900 dark:text-white">
                                <div>
                                  <span style="font-weight: 500">General Data Protection Regulation (GDPR)</span>: <br />
                                  I am informed and agree that as part of the {{ __txt_Event_Name }} workshop in {{ __txt_Event_City }}, my
                                  personal data will be processed and collected by Atout France. Atout France will take all necessary
                                  precautions to preserve the security and confidentiality of the collected data and in particular to
                                  prevent them from being distorted, damaged or communicated to unauthorized third parties.
                                </div>
                              </label>
                              <div *ngIf="submitted && firstVisitorForm.controls.dsgvo_accepted.invalid" class="error">
                                Accept General Data Protection Regulation is required
                              </div>
                            </div>

                            <div class="form-group form-check" *ngIf="showMedia">
                              <input type="checkbox" formControlName="media_accepted" id="media_accepted" class="form-check-input" />
                              <label for="media_accepted" class="block mb-2 text-sm text-gray-900 dark:text-white">
                                <div>
                                  I authorize Atout France to use the photographs taken, films shot and any recordings on which I appear, on
                                  a non-commercial basis and for the entire world, including sea and air space, on the Site, the Atout
                                  France websites (france. fr and www.atout-france.fr), social networks (Facebook, Twitter, Instagram,
                                  YouTube, LinkedIn) and other promotional media of Atout France for the promotion of the Workshop and any
                                  future editions, for a period of two(2) years from October 16, 2024.
                                </div>
                              </label>
                              <div *ngIf="submitted && firstVisitorForm.controls.dsgvo_accepted.invalid" class="error">
                                Accept Media Agreement is required
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="submitted && firstVisitorForm.invalid" class="error">Please check the mandatory fields.</div>
                    <p>&nbsp;</p>
                    <div class="step-footer" *ngIf="_isAdmin != 1">
                      <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                      <button mat-raised-button (click)="submitFirststart()" color="primary">Submit</button>
                    </div>
                  </mat-step>

                  <ng-template matStepperIcon="done">
                    <mat-icon>done</mat-icon>
                  </ng-template>
                  <ng-template matStepperIcon="edit">
                    <mat-icon>no icon</mat-icon>
                  </ng-template>
                  <ng-template matStepperIcon="number">
                    <mat-icon>no icon</mat-icon>
                  </ng-template>
                </mat-horizontal-stepper>
                <div *ngIf="_isAdmin == 1">
                  <a href="{{ _accounting_link }}" mat-raised-button color="secondary">Print Accounting Info</a> &nbsp;
                  <button mat-raised-button (click)="submitFirststart()" color="primary">Save</button> &nbsp;
                </div>
              </form>

              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>

              <!-- MAIN CONTENT END -->
            </div>
          </div>
        </div>
      </div>
    </main>
    <footer class="p-4 md:px-6 md:py-8 dark:bg-gray-900">
      <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">&copy; 2023 Atout France - All Rights Reserved.</span>
    </footer>
  </div>
</div>
