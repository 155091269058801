import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CookieService } from 'ngx-cookie-service';

import { TranslateModule } from '@ngx-translate/core';
import { LOCALE_ID, Inject } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
//import localeDeAt from '@angular/common/locales/de-AT';
registerLocaleData(localeDe, 'de-DE', localeDeExtra);
//registerLocaleData(localeDeAt, 'de-AT'); // de-AT
//registerLocaleData(localeDeAt, 'de-CH'); // de-CH

import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AppRoutingModule } from './app-routing.module';

import { environment } from '@env/environment';
import { CoreModule } from '@core';
import { SharedModule } from '@shared';
import { AuthModule } from '@app/auth';

import { PhutureLoginModule } from './phuture-login/phuture-login.module';
import { FirststartModule } from './firststart/firststart.module';
import { DashboardModule } from './dashboard/dashboard.module';

import { GalleryModule } from './gallery/gallery.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,

    TranslateModule.forRoot(),

    CoreModule,
    SharedModule,
    AuthModule,
    AppRoutingModule,

    PhutureLoginModule,
    FirststartModule,
    DashboardModule,
    GalleryModule,

    environment.production ? [] : AkitaNgDevtools.forRoot({ shallow: false, sortAlphabetically: true }),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'en-EN',
    },
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
