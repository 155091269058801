import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpParamsOptions, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { environment } from '@env/environment';

import { map, tap } from 'rxjs/operators';

import { SortDirection } from '@angular/material/sort';

/*
const HttpUploadOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
  }),
};
*/

export interface Exhibitor {
  t_exhibitorid: number;
  t_profileid: number;

  organisation: string;
  name: string;
  vorname: string;
  funktion: string;
  adresse: string;
  plz: string;
  stadt: string;
  telefon: string;
  fax: string;
  email: string;
  web: string;
  beschreibung2: string;
  info2: string;
  info3: string;

  // regions
  auvergne: number;
  bourgogne: number;
  bretagne: number;
  centreloire: number;
  corse: number;
  champagne: number;
  nordcalais: number;
  normandie: number;
  aquitaine: number;
  languedoc: number;
  outremer: number;
  paris: number;
  paysloire: number;
  picardie: number;
  paca: number;
  multiregions: number;

  alsace: number;
  limousin: number;
  lorraine: number;
  midipyrenes: number;
  poitoucharentes: number;
  rhonealpes: number;
  riveria: number;

  // activitees
  agencereceptive: number;
  centralereservation: number;
  destination: number;
  excursion: number;
  groupement: number;
  locationfluviale: number;
  musee: number;
  transporteur: number;
  autre: number;
  offreski: number;
  ski_station: number;
  ski_rental: number;
  residencetourisme: number;
  restaurant: number;

  bild1: string;
  bild2: string;
  bild3: string;
  logo: string;

  beschreibung: string;
  info1: string;
  category: string;

  created: string;
  createdby: string;
  changed: string;
  changedby: string;

  sort: string;

  chkcity1: number;
  chkcity2: number;
  //chkcity3: number;
  //chkcity4: number;
  //chkcity5: number;

  personsort: number;
  room: number;
  monaco: number;
  thaiti: number;
  luxury: number;
  mice: number;

  profile_image: string;

  storno_accepted: number;
  dsgvo_accepted: number;
  agb_accepted: number;
  media_accepted: number;

  special_wishes: string;
  uid: string;

  /*
  garage_day1: number;
  garage_day2: number;

  attendance_event: number;

  lunch_day1: number;
  lunch_day2: number;


  hotel_stay_night: number;
  transfer_arrival: number;
  transfer_return: number;
  data1: number;
  flight_booked: number;
  data2: string;

  decharge_resp_civile: number;
  estimate: string;

  convention_fee_paid: number;
  hotel_booked: number;
  fully_booked: number;

  */

  activated: number;
  approved: number;
  firststart_done: number;

  beuser_id: number;
  notes: string;
}

export interface ExhibitorSingle {
  data: Exhibitor;
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

export interface ExhibitorTable {
  data: Exhibitor[];
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
  }),
};

//
// --------------------------------------------------------------
//

@Injectable({
  providedIn: 'root',
})
export class ExhibitorService {
  errorMsg: string = '';

  constructor(private httpClient: HttpClient) {}

  /**
   * GET ALL
   *
   * @return response()
   */

  public getExhibitors(
    pageNumber: Number,
    pageSize: Number,
    sort: string,
    order: SortDirection,
    searchTerm: string,
    filtersArgs: string
  ): Observable<ExhibitorTable> {
    const apiUrl = `${environment.serverUrl}/exhibitor`;
    // '?page=${pageNumber}&per_page=${pageSize}&sort=${sort}&sorder=${order}`

    return this.httpClient.get<ExhibitorTable>(apiUrl, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
      }),
      params: new HttpParams()
        .set('page', '' + pageNumber)
        .set('per_page', '' + pageSize)
        .set('sort', sort)
        .set('sorder', order)
        .set('sterm', searchTerm)
        .set('filters', filtersArgs),
    });
  }

  /**
   * GET / FIND BY ID
   *
   * @return response()
   */
  getExhibitorById(id: number): Observable<ExhibitorSingle> {
    const apiUrl = `${environment.serverUrl}/exhibitor`;

    return this.httpClient
      .get<ExhibitorSingle>(apiUrl, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin': '*',
        }),
        params: new HttpParams().set('id', '' + id),
      })
      .pipe(
        map((response: ExhibitorSingle) => {
          return {
            ...response,
          };
        })
      );
  }

  /**
   * GET / FIND BY FILTER
   *
   * @return response()

  getVisitorByParams(data: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    //const data: any = { this: 'thisThing', that: 'thatThing', other: 'otherThing'};

    const httpParams: HttpParamsOptions = { fromObject: data } as HttpParamsOptions;

    const options = { params: new HttpParams(httpParams), headers: headers };

    return this.httpClient.get<Visitor[]>(environment.serverUrl + '/visitor/', options).pipe(
      tap((resp) => {
        //this.repo.setQuizes( resp || []);
        return resp;
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }
  */

  /**
   * CREATE
   *
   * @return response()

  createVisitor(post: any): Observable<any> {
    return this.httpClient.post<any>(environment.serverUrl + '/visitor/', JSON.stringify(post), httpOptions).pipe(
      map((response) => {
        return {
          ...response.data,
          id: response.data.quuid,
          quizdata: '',
        };

      }),
      tap((response: any) => {
        console.log(response);
        //this.repo.addQuiz(response);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }
  */

  /**
   * UPDATE
   *
   * @return response()

  updateVisitor(person: Visitor): Observable<any> {
    let id = person.t_visitorid;
    const data = {
      //'id': quiz.qdata.id,
      quuid: '' + person.t_visitorid,
    };

    // do the update
    return this.httpClient.post<any>(environment.serverUrl + '/visitor/' + id, JSON.stringify(data), httpOptions).pipe(
      map((response) => {
        console.log('map');
        return {
          ...response.data,
          id: response.data.quuid,
          quizdata: '',
        };
      }),
      tap((response: any) => {
        console.log('tAP');
        //this.repo.updateQuiz(response);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }
*/

  /**
   * DELETE
   *
   * @return response()

  deleteVisitor(id: string) {
    return this.httpClient.delete(environment.serverUrl + '/visitor/' + id, httpOptions).pipe(
      tap((value) => {
        //this.repo.removeQuiz(id);
      }),
      catchError((error) => {
        let errorMsg: string = '';
        if (error.error instanceof ErrorEvent) {
          this.errorMsg = `Error: ${error.error.message}`;
        } else {
          this.errorMsg = this.getServerErrorMessage(error);
        }

        return throwError(errorMsg);
      })
    );
  }
*/

  /**
   * Write code on Method
   *
   * @return response()
   */
  private getServerErrorMessage(error: HttpErrorResponse) {
    console.log('custom service error handler');

    switch (error.status) {
      case 0: {
        // A client-side or network error occurred. Handle it accordingly.
        //console.error('An error occurred:', error.error);
        return `An error occurred: ${error.error}`;
      }
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.error}`;
      }

      // Return an observable with a user-facing error message.
      //return throwError(() => new Error('Something bad happened; please try again later.'));
    }
  }
}
